import React, { useEffect, useRef, useState } from "react";
import {
    Grid
} from "@mui/material";
import FieldMap from ".";

const MetaDataMapping = (props) => {
    const { value, data, onChange, elementProps } = props;
    const { compType } = data;
    const previousCardRef = useRef(null);
    const [selectedCard, setSelectedCard] = useState(null);
    const updatedData = { ...data, selectedCard }
    const FieldComponent = FieldMap[compType] || null;

    useEffect(() => {
        setSelectedCard(elementProps?.metadatamapping)
    }, [elementProps?.metadatamapping])

    const handleCardSelect = (data) => {
    
        previousCardRef.current = selectedCard;
        setSelectedCard(data);
        onChange(data);
    };

    return (
        <Grid item xs={12}>
            {FieldComponent ? (
            <FieldComponent
                element={elementProps}
                value={data?.value}
                data={updatedData}
                onChange={handleCardSelect}
            />
            )
            :
            null
        }
        </Grid>
    );
};

export default MetaDataMapping;
