import { useEffect, useState, createContext, useContext, useMemo } from "react";
import { getSelectedText } from "../utils/helper";

const EditorContext = createContext();

export const useEditorSelection = (editor) => {
  const [textSelected, setTextSelected] = useState(false);
  useEffect(() => {
    if (editor?.selection) {
      const text = getSelectedText(editor);
      if (text?.length > 0) {
        setTextSelected(true);
      } else {
        setTextSelected(false);
      }
    }
  }, [editor?.selection]);

  return [textSelected];
};

export const EditorProvider = ({ editor, theme, children }) => {
  const [event] = useMouseMove();
  const [previous, setPrevious] = useState("");
  const [drop, setDrop] = useState(0);
  const [selectedPath, setSelectedPath] = useState(null);
  const path = event?.target?.getAttribute("data-path");
  const [popupType, setPopupType] = useState(""); // opened popup name in the editor will be stored
  const [openAI, setOpenAI] = useState("");

  const onDrop = () => {
    setDrop(drop + 1);
  };

  const value = useMemo(() => {
    if (path) {
      setPrevious(path);
      return { hoverPath: path };
    } else {
      return { hoverPath: previous };
    }
  }, [path, editor?.selection]);

  return (
    <EditorContext.Provider
      value={{
        ...(value || {}),
        onDrop: onDrop,
        drop,
        theme: theme,
        selectedPath: selectedPath,
        setSelectedPath: setSelectedPath,
        popupType,
        setPopupType,
        openAI,
        setOpenAI,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
};

export const useEditorContext = () => {
  return useContext(EditorContext);
};

const useMouseMove = () => {
  const [event, setEvent] = useState({ target: null });
  useEffect(() => {
    addListener();
    return () => {
      removeListener();
    };
  }, []);

  const onMouseMove = (e) => {
    setEvent({ target: e.target });
  };

  const addListener = () => {
    document.addEventListener("mousemove", onMouseMove);
  };

  const removeListener = () => {
    document.removeEventListener("mousemove", onMouseMove);
  };

  return [event];
};

export default useMouseMove;
