import React from "react";
import { Box } from "@mui/material";
import { getTRBLBreakPoints, getBreakPointsValue } from "../../../helper/theme";

const FormNumbers = (props) => {
    const { fieldProps } = props;
    const {
        bannerSpacing,
        height,
        borderRadius,
        borderStyle,
        borderWidth,
        borderColor,
        textColor,
        bgColor,
        lockSpacing,
        ...rest
    } = fieldProps;

    const onChange = (e) => {
        e.preventDefault();
    };

    return (
        <div style={{ width: "100%", display: "flex" }} contentEditable={false}>
            <Box
                component={"input"}
                {...rest}
                type="number"
                inputProps={{ min: 0 }}
                onChange={onChange}
                onWheel={(e) => e.target.blur()}
                sx={{
                    width: "100%",
                    border: `1px solid ${borderColor || "#FFF"}`,
                    padding: {
                        ...getTRBLBreakPoints(bannerSpacing),
                    },
                    height: height || "auto",
                    borderColor: borderColor || "transparent",
                    borderWidth: borderWidth || "1px",
                    borderRadius: {
                        ...getBreakPointsValue(
                            borderRadius || {},
                            null,
                            "overrideBorderRadius",
                            true
                        ),
                    },
                    borderStyle: borderStyle || "solid",
                    color: textColor || "#000",
                    background: bgColor || "transparent",
                    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                        '-webkit-appearance': 'none',
                        margin: 0,
                    },
                    '&[type=number]': {
                        '-moz-appearance': 'textfield', // Hide spin buttons for Firefox
                    },
                }}
            />
        </div>
    );
};

export default FormNumbers;
